import './App.css';
import ThreeBackground from './ThreeBackground';
import WebContent from './WebContent';

const App = () => {
  
  return (
    <div className="App">
      <ThreeBackground />
      <WebContent />
    </div>
  );
}

export default App;
